<template>
    <div class="w-75 mx-auto">
        <b-col sm="12" class="d-flex justify-content-center" v-if="!hasActiveSubscription">
            <b-form-group label="Subscription duration" v-slot="{ ariaDescribedby }">
                <b-form-radio-group id="radio-slots" v-model="subscriptionGateModel.duration"
                                    :options="subOptions" :aria-describedby="ariaDescribedby"
                                    name="radio-options-slots">
                </b-form-radio-group>
            </b-form-group>
        </b-col>
        <b-row class="mt-3 mb-4">
            <b-col v-if="subscriptionTemplateList" v-for="item in subscriptionTemplateList" :key="'stl' + item.id" sm="12"
                   lg="6" xl="6">
                <div :class="changeActiveColor(item.id)">
                    <span v-if="item.price > 0" class="price">
                        Price: {{ item.price }}<span v-if="item.currency">
                            {{ item.currency.currencyCode }}
                        </span>
                    </span>
                    <span v-else class="price">
                        Price: FREE
                    </span>
                    <div class="pt-4">
                        <p class="text-danger" v-if="item.hasSub">
                            {{ item.hasSub.id }} - {{ item.hasSub.isCurrent }}
                        </p>
                        <h4 class="text-center">{{ item.name }}</h4>
                        <h6 v-if="item.description"><em>{{ item.description }}</em></h6>
                        <div class="row my-4">
                            <button v-if="!hasActiveSubscription && item.price > 0" @click="addSubscriptionToGate(item.id,20)" class="btn btn-primary m-auto"
                                    type="button">
                                Buy
                            </button>
                            <button v-if="!hasActiveSubscription && item.price === 0" @click="addSubscriptionToGate(item.id,10)" class="btn btn-primary m-auto"
                                    type="button">
                                Add FREE subscription
                            </button>
                        </div>
                        <button v-if="!hasActiveSubscription && item.price > 0" @click="addSubscriptionToGate(item.id,30)" class="btn btn-primary m-auto"
                                    type="button">
                                Add subscription to invoice
                        </button>
                        <!-- Modal for add Subscription -->
<!--                         <b-modal :id="'modal' + item.name" ref="addsubscription" title="Add subscription" hide-footer>
                            <b-row no-gutters align-v="center" class="mt-2">
                                <b-col sm="12">
                                    <b-form-group label="Subscription duration" v-slot="{ ariaDescribedby }">
                                        <b-form-radio-group id="radio-slots" v-model="subscriptionGateModel.duration"
                                                            :options="subOptions" :aria-describedby="ariaDescribedby"
                                                            name="radio-options-slots">
                                        </b-form-radio-group>
                                    </b-form-group>
                                </b-col>
                                <b-row class="mt-2 mb-0">
                                    <b-col cols="12">
                                        <div class="float-left">
                                            <b-button variant="warning" @click="$bvModal.hide(modalId(item.name))">
                                                Cancel
                                            </b-button>
                                        </div>
                                        <div class="float-right">
                                            <b-button variant="success"
                                                      @click="addSubscriptionToGate(item, modalId(item.name))">
                                                Add
                                            </b-button>
                                        </div>
                                    </b-col>
                                </b-row>
                            </b-row>
                        </b-modal> -->
                        <div v-if="isActiveSubscription(item.id)">
                            <button v-if="stateUser.userRole >= userRoles.DemexAdmin && hasActiveSubscription && !notActivatedSubscription"
                                v-b-modal="modalId(item.name)" class="btn btn-warning m-auto" type="button">
                            Change subscription
                        </button>
                        <button v-if="stateUser.userRole < userRoles.DemexAdmin && hasActiveSubscription && !notActivatedSubscription" @click="addSubscriptionToGate(item.id,20)" class="btn btn-primary m-auto"
                                    type="button">
                                Buy upgrade
                        </button>
                        </div>


                        <!-- Modal for change Subscription -->
                        <b-modal :id="'modal' + item.name" ref="changesubscription" title="Change subscription" hide-footer>
                            <b-row no-gutters align-v="center" class="mt-2">
                                <b-col sm="12">
                                    <b-form-group label="Subscription duration" v-slot="{ ariaDescribedby }">
                                        <b-form-radio-group id="radio-slots" v-model="subscriptionGateModel.duration"
                                                            :options="subOptions" :aria-describedby="ariaDescribedby"
                                                            name="radio-options-slots">
                                        </b-form-radio-group>
                                    </b-form-group>
                                </b-col>
                                <b-row class="mt-2 mb-0">
                                    <b-col cols="12">
                                        <div class="float-left">
                                            <b-button variant="warning" @click="$bvModal.hide(modalId(item.name))">
                                                Cancel
                                            </b-button>
                                        </div>
                                        <div class="float-right">
                                            <b-button v-if="item.price > 0" variant="success"
                                                      @click="changeSubscriptionToGate(item, modalId(item.name),30)">
                                                Change for Invoice
                                            </b-button>
                                            <b-button v-if="item.price === 0"  variant="success"
                                                      @click="changeSubscriptionToGate(item, modalId(item.name),10)">
                                                Change for FREE period
                                            </b-button>
                                        </div>
                                    </b-col>
                                </b-row>
                            </b-row>
                        </b-modal>
                        <div class="p-2">
                            <p class="text-center mb-0">What´s included?</p>
                            <b-collapse :id="collapse_id(item.id)">
                                <b-card>
                                    <ul v-if="item.subscriptionPermissions" v-for="perm in item.subscriptionPermissions">
                                        <li>{{ perm }}</li>
                                    </ul>
                                </b-card>
                            </b-collapse>
                            <button @click="toggle_id(item.id)" variant="primary" class="text-center mb-0 w-100">
                                <div class="icon-box mt-3"><b-icon stacked icon="chevron-down"></b-icon></div>
                            </button>
                        </div>
                    </div>
                </div>
            </b-col>
        </b-row>
    </div>
</template>

<style scoped>
    .btn-border {
        color: #003440;
        background-color: transparent;
        border-color: #003440;
    }

    .bg-white {
        background-color: #fff;
    }

    a {
        text-decoration: unset
    }

    .sub-box {
        min-height: 290px;
        border-radius: 10px;
        border: 2px solid #003440
    }

        .sub-box h4 {
            text-transform: uppercase
        }

    .dmx-gate-card {
        background-color: #fff;
        padding: 25px 15px;
    }

    .dmx-gate-card-active {
        background-color: #adf3ad;
        padding: 25px 15px;
    }

    .dmx-gate-card-inactive {
        background-color: #e9f3ad;
        padding: 25px 15px;
    }

    .price {
        position: absolute;
        top: 20px;
        right: 32px;
    }

    .selected {
        background-color: #f3f2e8;
    }

    .icon-box {
        width: 30px;
        height: 30px;
        margin: auto;
        background-color: #003440;
        border-radius: 50%;
        padding: 6px;
    }

        .icon-box .b-icon {
            color: #fff
        }

    .card {
        border: none;
        font-size: 14px;
        background-color: transparent
    }

    .not-collapsed .icon-box .b-icon {
        transform: rotate(180deg);
        margin-bottom: 2px;
    }

    .card ul {
        margin-bottom: 0;
        text-transform: uppercase
    }
</style>

<script>
    import { mapState } from 'vuex';
    import router from '@/router';
    import subscriptionService from '@/services/subscriptionService';
    import { UserRoles } from '@/variables/variables';
    export default {
        components: {
        },
        props: {
            gate: {},
        },
        data() {
            return {
                subscriptionTemplateList: [],
                userRoles: UserRoles,
                subscriptionGateModel: {
                    subscriptionId: 0,
                    gateId: 0,
                    duration: 1,
                    paymentMethod:0
                },
                allGateSubscriptions: [],
                hasActiveSubscription: false,
                notActivatedSubscription: false,
                activeSubscriptionId: 0,
                subOptions: [

                    { text: 'One month', value: 1 },
                    { text: 'Six months', value: 6 },
                    { text: 'Twelve months', value: 12 },
                ],
            }
        },
        methods: {
            modalId(i) {
                return 'modal' + i;
            },
            changeActiveColor(id) {
                if (id === this.activeSubscriptionId) {

                    return "sub-box dmx-gate-card-active";
                }
                else {

                    return "sub-box dmx-gate-card";
                }

            },
            isActiveSubscription(id) {
                if (id === this.activeSubscriptionId) {

                    return true;
                }
                else {

                    return false;
                }

            },
            addSubscriptionToGate(subId, method) {

                this.subscriptionGateModel.subscriptionId = subId;
                this.subscriptionGateModel.gateId = this.gate.id;
                this.subscriptionGateModel.paymentMethod = method;
                subscriptionService.addSubscriptionToGate(this.subscriptionGateModel)
                    .then((data) => {
                        if(this.subscriptionGateModel.paymentMethod == 20) {
                            // Pay with card and start nets
                            router.push({ name: 'Nets', params: { gateId: this.gate.id } })
                        }else {
                            router.go(-1);
                        }

                        console.log(data);
                    })
                    .catch(function (error) {
                        alert(error);
                    });
            },
            getSubscriptionTemplateList() {
                subscriptionService.getSubscriptionTemplateList(this.gate.id)
                    .then((data) => {
                        this.subscriptionTemplateList = data;
                    })
                    .catch(function (error) {
                        alert(error);
                    });
            },
            getGateSubscriptionList() {
                subscriptionService.getGateSubscriptionsById(this.gate.id)
                    .then((data) => {
                        this.allGateSubscriptions = data;
                        if (this.allGateSubscriptions.some(x => x.isCurrent)) {
                            this.hasActiveSubscription = true;

                            let subobj = this.allGateSubscriptions.find(x => x.isCurrent);
                            if (subobj) {
                                this.activeSubscriptionId = subobj.subscriptionId;
                            }
                        }
                        if (this.allGateSubscriptions.some(x => x.subscriptionExpiryDateUtc === null)) {
                            this.notActivatedSubscription = true;
                        }

                    })
                    .catch(function (error) {
                        alert(error);
                    });
            },
            collapse_id(id) {
                return 'collapse_sub_' + id
            },
            toggle_id(id) {
                this.$root.$emit('bv::toggle::collapse', 'collapse_sub_' + id)
            },
            closePayment() {
                this.$refs['makepayment'].hide();
            },
            changeSubscriptionToGate(obj, str,method) {
                this.subscriptionGateModel.subscriptionId = obj.id;
                this.subscriptionGateModel.gateId = this.gate.id;
                this.subscriptionGateModel.paymentMethod = method;
                subscriptionService.addSubscriptionToGate(this.subscriptionGateModel)
                    .then((data) => {
                        this.$bvModal.hide(str);
                        if(this.subscriptionGateModel == 20) {
                            // Pay with card and start nets
                            router.push({ name: 'Nets', params: { gateId: this.gate.id } })
                        }else {
                            router.go(-1);
                        }
                    })
                    .catch(function (error) {
                        alert(error);
                    });
            },
        },
        computed: {
            ...mapState('authentication', {
                stateUser: 'user',
            }),
        },
        mounted() {
            if(this.gate.id > 0) {
                this.getSubscriptionTemplateList();
            this.getGateSubscriptionList();
            this.$root.$on('bv::collapse::state', (collapseId, isJustShown) => {
            })
            }

        }
    }
</script>