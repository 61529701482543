<template>
    <div>
        <b-row align-v="center">
            <b-col v-if="freeUsers" cols="4">
                Search and add a user to a gate
                <vue-bootstrap-typeahead v-if="gate.id > 0" :data="freeUsers" v-model="searchUsers" 
                :serializer="t => t.email" placeholder="Type email ..." backgroundVariant="light" @hit="selectUser" />
            </b-col>
            <b-col cols="3">
                    Select initial gate role
                    <b-select v-model="selectedGateRole" :options="availableGateRoles"></b-select>
                </b-col>
                <b-col cols="5">
                    {{ $t('common_validto') }}
                    <b-form-datepicker v-model="validToDateTime"></b-form-datepicker>
                </b-col>

        </b-row>
        <b-row class="d-flex align-items-end justify-content-end">
            <b-col cols="3">
                    <b-button variant="success btn-block" @click="connectGate" class="mt-4">Add</b-button>
                </b-col>
        </b-row>
    </div>
</template>

<style scoped></style>

<script>
import { mapState } from 'vuex';
import VueBootstrapTypeahead from 'vue-bootstrap-typeahead';
import accountService from '@/services/accountService';
import gateService from '@/services/gateService';

export default {
    props: {
        gate: {},
        hasOwner: false,
        userId: ''
    },
    components: {
        'vue-bootstrap-typeahead': VueBootstrapTypeahead
    },
    data: () => ({
            searchUsers: '',
            freeUsers: [],
            selectedUser: false,
            selectedGateRole: 10,
            validToDateTime: null,
            availableGateRoles: [],
    }),
    methods: {
        cancel() {
            this.$root.$emit('bv::hide::modal', 'adduser-modal');
        },
        fetchUsers() {
            this.freeUsers = [];
            accountService.fetchUsers(this.gate.id)
            .then((data) => {
                this.freeUsers = data;
            }).catch(function (error) {
                alert(error);
            });
        },
        selectUser(user) {
                this.selectedUser = user;
            },
        connectGate() {
                accountService.connectGate({ gateId: this.gate.id, userId: this.selectedUser.id, gateRole: this.selectedGateRole, validTo: this.validToDateTime })
                    .then((data) => {
                        this.searchGates = '';
                        this.selectedGate = false;
                        this.$root.$emit('dmx::saved::gateUserConnect');
                    });
            },
            getAvailableGateRoles() {
                this.avaiableGateRoles = [];
                gateService.getAvailableGateRoles()
                    .then((data) => {
                        this.availableGateRoles = [];
                        for (let key in data) {
                             this.availableGateRoles.push({ value: data[key], text: this.$t('gateRole_' + key) });
                        }
                        if(this.hasOwner){
                            this.availableGateRoles = this.availableGateRoles.filter(x => x.value !== 40);
                        }
                    });
            },
    },
    computed: {
        ...mapState({
            stateUser: store => store.authentication.user,
        }),
    },
    mounted() {
        this.fetchUsers();
        this.getAvailableGateRoles();
    }
}
</script>