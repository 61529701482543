import { render, staticRenderFns } from "./gate-subscriptions.vue?vue&type=template&id=45a53b82&scoped=true&"
import script from "./gate-subscriptions.vue?vue&type=script&lang=js&"
export * from "./gate-subscriptions.vue?vue&type=script&lang=js&"
import style0 from "./gate-subscriptions.vue?vue&type=style&index=0&id=45a53b82&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "45a53b82",
  null
  
)

export default component.exports